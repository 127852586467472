import React from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";

const Home = () => {
  return (
    <div>
      <Banner />
      <Header/>
      <div>
        <section className="bg-[#1e2026]">
          <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
              <div className=" sm:text-lg text-gray-400">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-white">
                  SEAMLESS AND SMOOTH ACCESS —
                </h2>
                <p className="mb-8 font-light lg:text-xl">
                  Experience effortless access to <b>WEALTH RING</b>, a dynamic
                  community-building platform where innovation meets simplicity.
                  Our platform ensures a smooth, intuitive journey, making it
                  easier than ever to connect, grow, and thrive within a
                  supportive community dedicated to your success.
                </p>
                <ul
                  role="list"
                  className="pt-8 space-y-5 border-t  my-7 border-gray-700"
                >
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      No Document Required
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 \text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      Fully and truly decentralised platform
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      Totally ownership free
                    </span>
                  </li>
                </ul>
                <p className="mb-8 font-light lg:text-xl">
                  Join us now and secure your financial future with our
                  innovative platform.
                </p>
              </div>
              <img
                className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                src="/feature_1.png"
                alt="dashboard feature image"
              />
            </div>
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
              <img
                className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
                src="/feature_2.png"
                alt="feature image 2"
              />
              <div className=" sm:text-lg text-gray-400">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-white">
                  Distinctive features of our platform -
                </h2>
                <p className="mb-8 font-light lg:text-xl">
                  At <b>WEALTH RING</b>, we are committed to providing unparalleled
                  business opportunities that help our users grow and secure
                  their futures. Our innovative helping platform connects
                  individuals with a community of investors.
                </p>
                <ul
                  role="list"
                  className="pt-8 space-y-5 border-t border-gray-700"
                >
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      Very negligible entry/ start up cost ( 2 USD )
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      100% instant disbursal of rewards &amp; profits
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      Earn upto 16770950.83 USD
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      No team building compulsory
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-purple-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="text-base font-medium leading-tight text-white">
                      Huge passive income/profits
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* section 2 */}

        <section className="bg-[#14151A] transition-colors duration-1000">
          <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
            <div className="col-span-2 mb-8">
              <p className="text-lg font-medium text-purple-500">
                Trusted Worldwide
              </p>
              <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight  md:text-3xl text-white">
                Globally Trusted, Valued by Thousands -
              </h2>
              <p className="font-light  sm:text-xl text-gray-400">
                At <b>WEALTH RING</b>, we’re committed to dismantling barriers and
                fostering a more inclusive financial landscape. Join us to
                embark on the path to a brighter, financially independent
                future.
              </p>
            </div>
            <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#f0b90b"
                    d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                  ></path>
                </svg>
                <h3 className="mb-2 mt-3 text-2xl font-bold text-white">
                  Polygon
                </h3>
                <p className="font-light text-gray-400">
                  Empowering decentralized finance with efficiency and
                  scalability on Polygon Chain.
                </p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#f0b90b"
                    d="M6.544 5.061L12 2l5.455 3.061l-2 1.133L12 4.261L8.55 6.194zm10.911 3.867l-2-1.134L12 9.728L8.55 7.794L6.544 8.928v2.26l3.445 1.934v3.861l2.01 1.134l2.006-1.134v-3.86l3.45-1.934zm0 6.128v-2.267l-2 1.133v2.261zm1.428.8l-3.45 1.927v2.267l5.456-3.067v-6.122l-2.006 1.128zm-2.005-8.862l2 1.134v2.26l2.01-1.127V6.994l-2.005-1.133L16.878 7zm-6.89 11.612v2.26L12 22l2.005-1.133V18.61L12 19.74l-2.006-1.134zM6.545 15.05l2 1.133v-2.26l-2-1.134v2.267zM9.99 6.994L12 8.128l2.006-1.134L12 5.861L9.994 7zM5.116 8.128l2.006-1.134l-2-1.133L3.11 7v2.267l2.005 1.128zm0 3.861L3.11 10.861v6.122l5.455 3.067v-2.272L5.122 15.85v-3.867z"
                  ></path>
                </svg>
                <h3 className="mb-2 mt-3 text-2xl font-bold  text-white">
                  USD
                </h3>
                <p className="font-light text-gray-400">
                  Efficient coin transactions facilitated seamlessly through
                  POLYGON's infrastructure.
                </p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40px"
                  height="40px"
                  viewBox="0 0 256 256"
                >
                  <g fill="none">
                    <rect
                      width="256"
                      height="256"
                      fill="#65afff"
                      rx="60"
                    ></rect>
                    <path
                      fill="#000"
                      d="m165.536 25l-37.53 58.79H53L90.503 25z"
                      opacity="0.45"
                    ></path>
                    <path
                      fill="#000"
                      d="M128.006 83.79h75.033L165.536 25H90.503z"
                      opacity="0.6"
                    ></path>
                    <path
                      fill="#000"
                      d="m90.503 142.557l37.503-58.767L90.503 25L53 83.79z"
                      opacity="0.8"
                    ></path>
                    <path
                      fill="#000"
                      d="m90.867 230.742l37.529-58.79h75.033l-37.53 58.79z"
                      opacity="0.45"
                    ></path>
                    <path
                      fill="#000"
                      d="M128.396 171.952H53.363l37.503 58.79H165.9z"
                      opacity="0.6"
                    ></path>
                    <path
                      fill="#000"
                      d="m165.899 113.185l-37.503 58.767l37.503 58.79l37.529-58.79z"
                      opacity="0.8"
                    ></path>
                  </g>
                </svg>
                <h3 className="mb-2 mt-3 text-2xl font-bold text-white">
                  Smart Contract
                </h3>
                <p className="font-light text-gray-400">
                  Automating agreements securely with transparent blockchain
                  smart contracts.
                </p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#9b1c1c"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5.636 5.636a9 9 0 1 0 12.728 12.728M5.636 5.636a9 9 0 1 1 12.728 12.728M5.636 5.636L12 12l6.364 6.364"
                  ></path>
                </svg>
                <h3 className="mb-2 mt-3 text-2xl font-bold text-white">
                  No Admin
                </h3>
                <p className="font-light text-gray-400">
                  Decentralized governance ensures autonomy and transparency
                  without administrators.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* section 3 */}

        <div className="items-center bg-[#1e2026] p-4 md:p-8 gap-8 lg:grid lg:grid-cols-2 xl:gap-16 flex flex-col-reverse">
          <div className="sm:text-lg  text-gray-400">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-white">
              Our Ecosystem Roadmap -
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Once we have a substantial user base on our platform, we will
              introduce a range of blockchain applications, gaming projects,
              tokens, exchanges, and Web 3.0 initiatives.
            </p>
            <p className="mb-8 font-light lg:text-xl">
              These projects will provide our users with ongoing, mutually
              rewarding profits and benefits from the community. Our disruptive
              business model is designed to create win-win situations, fostering
              exceptional growth for everyone involved.
            </p>
          </div>
          <img
            className="w-full mb-4 rounded-lg lg:mb-0 lg:flex"
            src="/dex.webp"
            alt="dashboard feature image"
          />
        </div>

        {/* section 4 */}
        <section className="bg-[#1e2026]">
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
            <figure className="max-w-screen-md mx-auto">
              <svg
                className="h-12 mx-auto mb-3 text-gray-600"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                ></path>
              </svg>
              <blockquote>
                <p className="text-xl font-medium  md:text-2xl text-white">
                  "Success begins with a single step. Believe in your potential,
                  seize opportunities, and never give up. Your dedication and
                  perseverance will turn dreams into reality. Start your journey
                  to greatness today!"
                </p>
              </blockquote>
            </figure>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
