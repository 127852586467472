import React, { useState } from "react";
import { FaCopy, FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

const ReferralLink = ({refId}) => {
  const [copyMessage, setCopyMessage] = useState('');

  const copyToClipboard = () => {
    const link = `https://wealthring.org/dashboard?ref=${refId}`;
    navigator.clipboard.writeText(link)
    .then(() => {
      toast.success("Link copied to clipboard!",{
        position: "top-center"
      })
      setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
    })
    .catch(err => {
      console.error("Failed to copy: ", err);
    });
  };
  return (
    <div className="flex justify-center px-4 md:p-0 mt-6">
      <div className="md:w-3/4 w-full">
        <div className="w-full flex justify-between items-center bg-whtie dark:bg-[#1e2026] dark:bg-opacity-40 rounded-lg shadow-lg p-4">
          <div className="overflow-x-auto overflow-y-hidden">
            <h1 className="text-lg font-bold mb-4 text-white">Referral Link</h1>
            <div className="flex justify-between items-center text-2xl">
              <span className="max-w-full cursor-pointer text-sm font-medium overflow-x-auto bg-blue-500 rounded-sm px-3 py-2 text-white whitespace-nowrap">
                https://wealthring.org/dashboard?ref={refId} 
              </span>
              <div className="pl-2 flex items-center">
              <FaCopy className="text-white cursor-pointer" onClick={copyToClipboard} />
              {copyMessage && <p className="text-green-500 text-sm pl-2">{copyMessage}</p>} {/* Feedback message */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLink;
